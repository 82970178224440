<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-spin :spinning="loading">
          <h2 style="text-align: center;">{{ noticeInfoData.title }}</h2>
          <p>{{ noticeInfoData.content }}</p>
          <p :key="index" v-for="(item, index) in noticeInfoData.imageListData">
            <img
              style="max-width: 100%"
              :src="item.url"
            />
          </p>
          <a-divider>配置信息</a-divider>
          <div>
            <a-form>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                label="公告类型"
              >
                <a-select
                  style="width: 200px;"
                  v-model="noticeInfoData.noticeType"
                  placeholder="请选择"
                  :disabled="true"
                >
                  <a-select-option :key="0" :value="-1">请选择</a-select-option>
                  <a-select-option v-for="item in typeItem" :key="item.itemId" :value="item.itemId">
                    {{ item.itemName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                label="发送方式"
              >
                <a-select
                  style="width: 200px;"
                  v-model="noticeInfoData.sendType"
                  placeholder="请选择"
                  :disabled="true"
                >
                  <a-select-option :key="0" :value="-1">请选择</a-select-option>
                  <a-select-option v-for="item in sendTypeList" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                label="发布时间"
              >
                <div>
                  <a-radio-group
                    v-model="noticeInfoData.isPublishTime"
                    style="width: 100%"
                    :disabled="true"
                  >
                    <a-radio :value="0">立刻</a-radio>
                    <a-radio :value="1">定时</a-radio>
                  </a-radio-group>
                </div>
                <div v-if="noticeInfoData.isPublishTime === 1">
                  <a-date-picker
                    :disabled="true"
                    v-model="noticeInfoData.publishTime"
                    style="width: 200px;"
                    showTime
                    valueFormat="YYYY-MM-DD HH:mm:ss"
                  />
                </div>
              </a-form-item>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                label="备注"
              >
                {{ noticeInfoData.remark }}
              </a-form-item>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                label="发送范围"
              >
                <a-radio-group
                  v-model="noticeInfoData.sendRangeTab"
                  style="width: 100%"
                  :disabled="true"
                >
                  <a-radio :value="1">按区域</a-radio>
                  <a-radio :value="2">全员</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                label="区域范围"
                v-if="noticeInfoData.sendRangeTab === 1"
              >
                <a-tabs :activeKey="noticeInfoData.sendRangeType" type="card">
                  <a-tab-pane tab="按小区" :key="1">
                    <a-card style="width: 400px;" title="已选小区">
                      <div style="max-height: 400px;overflow: hidden; overflow-y:auto;">
                        <p v-for="(row, index) in communityList" :key="index" v-if="communityTargetKeys.indexOf(row.key) >= 0">{{ row.title }}</p>
                      </div>
                    </a-card>
                  </a-tab-pane>
                  <a-tab-pane tab="按楼宇" :key="2">
                    <a-card style="width: 400px;" title="已选楼宇">
                      <div style="max-height: 400px;overflow: hidden; overflow-y:auto;">
                        <p v-for="(row, index) in buildinglist" :key="index" v-if="buildingTargetKeys.indexOf(row.key) >= 0">{{ row.title }}</p>
                      </div>
                    </a-card>
                  </a-tab-pane>
                  <a-tab-pane tab="按单元" :key="3">
                    <a-card style="width: 400px;" title="已选单元">
                      <div style="max-height: 400px;overflow: hidden; overflow-y:auto;">
                        <p v-for="(row, index) in unitList" :key="index" v-if="unitTargetKeys.indexOf(row.key) >= 0">{{ row.title }}</p>
                      </div>
                    </a-card>
                  </a-tab-pane>
                  <a-tab-pane tab="按个人" :key="4">
                    <a-card style="width: 400px;" title="已选用户">
                      <div style="max-height: 400px;overflow: hidden; overflow-y:auto;">
                        <p v-for="(row, index) in userTargetKeys" :key="index">{{ userNameList['u_' + row] }}</p>
                      </div>
                    </a-card>
                  </a-tab-pane>
                </a-tabs>
              </a-form-item>
            </a-form>
          </div>
        </a-spin>
      </div>
      <div style="text-align: center">
        <a-button
          @click="closeSeeInfo"
          style="margin-right: 10px;"
        >
          关闭
        </a-button>
        <a-dropdown v-if="noticeInfoData.checkStatus !== 1">
          <a-menu slot="overlay">
            <a-menu-item>
              <a-popconfirm title="确认要通过此公告吗？" @confirm="setNoticeCheckStatusApi(noticeInfoData.id,1)">
                <a>通过</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm title="确认要不通过此公告吗？" @confirm="setNoticeCheckStatusApi(noticeInfoData.id,2)">
                <a>不通过</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
          <a-button type="primary" :loading="dealCheckStatus">审核<a-icon type="down"/></a-button>
        </a-dropdown>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { noticeCondition, noticeInfo, setNoticeCheckStatus } from '@/api/notice'
import { allCommunityList } from '@/api/community'
import { allBuildinglist } from '@/api/building'
import { allUnitlist } from '@/api/unit'

export default {
  name: 'SeeInfo',
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      dealCheckStatus: false,
      labelCol: {
        lg: { span: 4 },
        sm: { span: 8 }
      },
      wrapperCol: {
        lg: { span: 20 },
        sm: { span: 17 }
      },
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      },
      isImageUpload: false,
      uploading: false,
      fileList: [],
      uploadImageInfo: {
        fileName: '',
        url: '',
        width: 0,
        height: 0,
        size: 0,
        bucket: 'dadaojianqi-syue',
        type: 0,
        transcoding: 0
      },
      loading: false,
      saveDisabled: false,
      saveLoading: false,
      searchDisabled: false,
      searchLoading: false,
      dataParams: {},
      errors: [],
      checkStatusList: [],
      sendTypeList: [],
      typeItem: [],
      isTab: 1,
      communityList: [],
      communityTargetKeys: [],
      unitList: [],
      unitTargetKeys: [],
      buildinglist: [],
      buildingTargetKeys: [],
      regionCommunity: [],
      regionBuilding: [],
      regionUnit: [],
      regionHouse: [],
      memberList: [],
      userArr: [],
      userTargetKeys: [],
      userNameList: {},
      noticeInfoData: {
        communityTargetKeys: [],
        buildingTargetKeys: [],
        unitTargetKeys: [],
        userTargetKeys: [],
        userNameList: {}
      },
      gotoType: 1
    }
  },
  created () {
    this.noticeConditionApi()
    this.allCommunityListApi()
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    noticeConditionApi () {
      const _this = this
      noticeCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.checkStatusList = res.result.checkStatusList
          _this.sendTypeList = res.result.sendTypeList
          _this.typeItem = res.result.typeItem
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    allCommunityListApi () {
      const _this = this
      allCommunityList().then((res) => {
        if (res.errorCode === 0) {
          _this.communityList = res.result.data
        }
      }).catch((err) => {
        console.log(err)
        _this.$message.error('系统错误')
      })
    },
    noticeInfoApi (keyId) {
      const _this = this
      _this.seeInfoVisible = true
      _this.loading = true
      noticeInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          _this.noticeInfoData = res.result
          _this.communityTargetKeys = res.result.communityTargetKeys
          _this.unitTargetKeys = res.result.unitTargetKeys
          _this.buildingTargetKeys = res.result.buildingTargetKeys
          _this.userTargetKeys = res.result.userTargetKeys
          _this.userNameList = res.result.userNameList
          _this.fileList = res.result.imageListData
          if (_this.noticeInfoData.sendRangeType === 2) {
            _this.allBuildinglistApi()
          } else if (_this.noticeInfoData.sendRangeType === 3) {
            _this.allUnitlistApi()
          } else if (_this.noticeInfoData.sendRangeType === 4) {
            _this.regionCommunityApi()
          }
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    },
    allBuildinglistApi () {
      const _this = this
      allBuildinglist().then((res) => {
        if (res.errorCode === 0) {
          _this.buildinglist = res.result.data
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    allUnitlistApi () {
      const _this = this
      allUnitlist().then((res) => {
        if (res.errorCode === 0) {
          _this.unitList = res.result.data
        }
      }).catch((err) => {
        console.log(err)
        _this.$message.error('系统错误')
      })
    },
    regionCommunityApi () {
      const _this = this
      allCommunityList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionCommunity = res.result.data
        }
      }).catch((err) => {
        console.log(err)
        _this.$message.error('系统错误')
      })
    },
    setNoticeCheckStatusApi (id, checkStatus) {
      const _this = this
      _this.dealCheckStatus = true
      setNoticeCheckStatus({ id: id, checkStatus: checkStatus }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.dealCheckStatus = false
          _this.seeInfoVisible = false
          _this.refreshData()
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        _this.dealCheckStatus = false
        console.log(err)
      })
    },
    refreshData () {
      this.$emit('refreshData', 1)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
