<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <a-back-top />
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="公告标题">
                <a-input placeholder="请输入公告标题" v-model="queryParam.title" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="发布人">
                <a-input placeholder="请输入发布人名称" v-model="queryParam.author" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="类型">
                <a-select
                  v-model="queryParam.noticeType"
                  placeholder="请选择类型"
                >
                  <a-select-option :key="0" :value="-1">请选择类型</a-select-option>
                  <a-select-option v-for="item in typeItem" :key="item.itemId" :value="item.itemId">
                    {{ item.itemName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="发送方式">
                  <a-select
                    v-model="queryParam.sendType"
                    placeholder="请选择发送方式"
                  >
                    <a-select-option :key="0" :value="-1">请选择发送方式</a-select-option>
                    <a-select-option v-for="item in sendTypeList" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="审核状态">
                  <a-select
                    v-model="queryParam.checkStatus"
                    placeholder="请选择审核状态"
                  >
                    <a-select-option :key="0" :value="-1">请选择审核状态</a-select-option>
                    <a-select-option v-for="item in checkStatusList" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="searchForm">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="gotoNotice(0)">添加公告</a-button>
      </div>
      <a-spin :spinning="loading">
        <a-tabs :activeKey="queryParam.listTab" @change="changeTab">
          <a-tab-pane tab="公告列表" :key="1">
            <a-list size="large" :pagination="pagination">
              <a-list-item :key="index" v-for="(item, index) in data">
                <a-list-item-meta :description="item.contentStr">
                  <a slot="title">
                    {{ item.title }}
                    <a-tag color="gold" v-if="item.checkStatus === 0">{{ item.checkStatusName }}</a-tag>
                    <a-tag color="green" v-else-if="item.checkStatus === 1">{{ item.checkStatusName }}</a-tag>
                    <a-tag color="red" v-else>{{ item.checkStatusName }}</a-tag>
                  </a>
                </a-list-item-meta>
                <div slot="actions">
                  <a @click="gotoNotice(item.keyId)">编辑</a>
                  <a-divider type="vertical" />
                  <a @click="showSeeInfo(item.keyId)">查看</a>
                  <a-divider type="vertical" />
                  <a-popconfirm title="确认要删除此公告吗？" @confirm="delNoticeApi(item.keyId)" :disabled="!(item.checkStatus !== 1)">
                    <a :disabled="!(item.checkStatus !== 1)">删除</a>
                  </a-popconfirm>
                </div>
                <div class="list-content">
                  <div class="list-content-item">
                    <span style="width: 90px;text-align: left;">发布人</span>
                    <p style="width: 90px; text-align: left;">{{ item.author }}</p>
                  </div>
                  <div class="list-content-item">
                    <span style="width: 60px;text-align: left;">发送方式</span>
                    <p style="width: 60px;text-align: left;">
                      <a-tag color="#2db7f5" v-if="item.sendType === 0">{{ item.sendTypeName }}</a-tag>
                      <a-tag color="#87d068" v-else-if="item.sendType === 1">{{ item.sendTypeName }}</a-tag>
                      <a-tag color="#108ee9" v-else>{{ item.sendTypeName }}</a-tag>
                    </p>
                  </div>
                  <div class="list-content-item">
                    <span style="width: 60px;text-align: left;">类型</span>
                    <p style="width: 60px;text-align: left;">{{ item.noticeTypeName }}</p>
                  </div>
                  <div class="list-content-item">
                    <span style="width: 150px;text-align: left;">发送时间</span>
                    <p style="width: 150px;text-align: left;">{{ item.publishTime }}</p>
                  </div>
                  <div class="list-content-item">
                    <span>添加时间</span>
                    <p>{{ item.createTime }}</p>
                  </div>
                </div>
              </a-list-item>
            </a-list>
          </a-tab-pane>
          <a-tab-pane tab="回收站" :key="2">
            <a-list size="large" :pagination="delpagination">
              <a-list-item :key="index" v-for="(item, index) in deldata">
                <a-list-item-meta :description="item.contentStr">
                  <a slot="title">
                    {{ item.title }}
                    <a-tag color="gold" v-if="item.checkStatus === 0">{{ item.checkStatusName }}</a-tag>
                    <a-tag color="green" v-else-if="item.checkStatus === 1">{{ item.checkStatusName }}</a-tag>
                    <a-tag color="red" v-else>{{ item.checkStatusName }}</a-tag>
                  </a>
                </a-list-item-meta>
                <div slot="actions">
                  <a @click="gotoNotice(item.keyId)">编辑</a>
                  <a-divider type="vertical" />
                  <a @click="showSeeInfo(item.keyId)">查看</a>
                  <a-divider type="vertical" />
                  <a-popconfirm title="确认要恢复此公告吗？" @confirm="recoveryNoticeApi(item.keyId)">
                    <a>恢复</a>
                  </a-popconfirm>
                </div>
                <div class="list-content">
                  <div class="list-content-item">
                    <span style="min-width: 90px;text-align: left;">发布人</span>
                    <p style="min-width: 90px;text-align: left;">{{ item.author }}</p>
                  </div>
                  <div class="list-content-item">
                    <span style="width: 60px;text-align: left;">发送方式</span>
                    <p style="width: 60px;text-align: left;">
                      <a-tag color="#2db7f5" v-if="item.sendType === 0">{{ item.sendTypeName }}</a-tag>
                      <a-tag color="#87d068" v-else-if="item.sendType === 1">{{ item.sendTypeName }}</a-tag>
                      <a-tag color="#108ee9" v-else>{{ item.sendTypeName }}</a-tag>
                    </p>
                  </div>
                  <div class="list-content-item">
                    <span style="width: 60px;text-align: left;">类型</span>
                    <p style="width: 60px;text-align: left;">{{ item.noticeTypeName }}</p>
                  </div>
                  <div class="list-content-item">
                    <span style="width: 150px;text-align: left;">发送时间</span>
                    <p style="width: 150px;text-align: left;">{{ item.publishTime }}</p>
                  </div>
                  <div class="list-content-item">
                    <span>添加时间</span>
                    <p>{{ item.createTime }}</p>
                  </div>
                </div>
              </a-list-item>
            </a-list>
          </a-tab-pane>
        </a-tabs>
      </a-spin>
      <see-info ref="seeinfo"></see-info>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { delNotice, noticeCondition, noticeList, recoveryNotice } from '@/api/notice'
import SeeInfo from '@/views/notice/components/SeeInfo'
export default {
  name: 'NoticeList',
  components: {
    SeeInfo
  },
  data () {
    return {
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addRoleFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        author: '',
        title: '',
        startTime: '',
        endTime: '',
        sendType: -1,
        noticeType: -1,
        checkStatus: -1,
        listTab: 1,
        status: 1,
        pageNo: 1,
        pageSize: 10
      },
      data: [],
      deldata: [],
      pagination: {
        onChange: (page, pageSize) => this.onChange(page, pageSize),
        onShowSizeChange: (current, pageSize) => this.showSizeChange(current, pageSize),
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        pageSize: 10,
        total: 0,
        defaultCurrent: 1,
        current: 1
      },
      delpagination: {
        onChange: (page, pageSize) => this.onChange(page, pageSize),
        onShowSizeChange: (current, pageSize) => this.showSizeChange(current, pageSize),
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        pageSize: 10,
        total: 0,
        defaultCurrent: 1,
        current: 1
      },
      regionCommunity: [],
      dealSuggestLoading: false,
      checkStatusList: [],
      sendTypeList: [],
      typeItem: [],
      loading: false,
      isTab: 1
    }
  },
  beforeRouteLeave (to, from, next) {
    // 打开详情页（或者下一个任意界面）之前，把筛选条件保存到localstorage，如果离开列表页并且打开的不是详情页则清除，也可以选择不清除
    if (to.name === 'GridNoticeInfo') {
      const condition = JSON.stringify(this.queryParam)
      localStorage.setItem('noticeListCondition', condition)
    } else {
      localStorage.removeItem('noticeListCondition')
    }
    next()
  },
  filters: {
  },
  created () {
    const searchCondition = localStorage.getItem('noticeListCondition')
    console.log('searchCondition', searchCondition)
    if (searchCondition !== null) {
      this.queryParam = JSON.parse(searchCondition)
    }
    this.noticeConditionApi()
    this.noticeListApi()
  },
  watch: {
    $route (val) {
      if (val.name === 'GridNoticeList') {
        const searchCondition = localStorage.getItem('noticeListCondition')
        console.log('searchCondition', searchCondition)
        if (searchCondition !== null) {
          this.queryParam = JSON.parse(searchCondition)
        }
        this.noticeConditionApi()
        this.noticeListApi()
      }
    }
  },
  methods: {
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    noticeConditionApi () {
      const _this = this
      noticeCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.checkStatusList = res.result.checkStatusList
          _this.sendTypeList = res.result.sendTypeList
          _this.typeItem = res.result.typeItem
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    noticeListApi () {
      const _this = this
      _this.loading = true
      if (_this.queryParam.listTab === 1) {
        _this.queryParam.status = 1
      } else {
        _this.queryParam.status = 0
      }
      console.log(_this.queryParam.status)
      noticeList(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          if (_this.queryParam.status === 1) {
            _this.data = res.result.data
            _this.pagination.pageSize = res.result.pageSize
            _this.pagination.total = res.result.totalCount
            _this.pagination.current = res.result.pageNo
          } else {
            _this.deldata = res.result.data
            _this.delpagination.pageSize = res.result.pageSize
            _this.delpagination.total = res.result.totalCount
            _this.delpagination.current = res.result.pageNo
          }
          console.log('pagination', _this.pagination)
        } else {
          _this.$message.error('系统错误')
        }
        _this.loading = false
      }).catch((err) => {
        console.log(err)
        _this.loading = false
      })
    },
    searchForm () {
      this.queryParam.pageNo = 1
      this.noticeListApi()
    },
    resetSearchForm () {
      console.log(this.isTab)
      this.queryParam = {
        author: '',
        title: '',
        startTime: '',
        endTime: '',
        sendType: -1,
        noticeType: -1,
        checkStatus: -1,
        listTab: this.isTab,
        status: 1,
        pageNo: 1,
        pageSize: 10
      }
    },
    onChange (page, pageSize) {
      if (this.isTab === 1) {
        this.pagination.current = page
        this.pagination.pageSize = pageSize
      } else {
        this.delpagination.current = page
        this.delpagination.pageSize = pageSize
      }

      this.queryParam.pageNo = page
      this.queryParam.pageSize = pageSize
      this.noticeListApi()
    },
    delNoticeApi (noticeId) {
      const _this = this
      delNotice({ keyId: noticeId }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.noticeListApi()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    recoveryNoticeApi (noticeId) {
      const _this = this
      recoveryNotice({ keyId: noticeId }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.noticeListApi()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeTab (e) {
      this.isTab = parseInt(e)
      this.queryParam.listTab = parseInt(e)
      console.log('listTab', this.queryParam.listTab)
      this.resetSearchForm()
      if (e === 1) {
        this.queryParam.status = 1
        this.noticeListApi()
      } else {
        this.queryParam.status = 0
        this.noticeListApi()
      }
      console.log('changeTab', e)
    },
    showSizeChange (current, pageSize) {
      if (this.isTab === 1) {
        this.pagination.current = 1
        this.pagination.defaultCurrent = 1
        this.pagination.pageSize = pageSize
      } else {
        this.delpagination.current = 1
        this.delpagination.defaultCurrent = 1
        this.delpagination.pageSize = pageSize
      }
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = pageSize
      this.noticeListApi()
    },
    showSeeInfo (keyId) {
      this.$refs.seeinfo.noticeInfoApi(keyId)
    },
    gotoNotice (noticeId) {
      this.$router.push({ path: '/notice/notice-info?noticeId=' + noticeId + '&type=1' })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;
  span {
    line-height: 20px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
